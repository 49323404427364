import { useQuery, UseQueryResult } from 'react-query';
import { AxiosRequestHeaders } from 'axios';
import API from '../api/phx';
import { useSession } from './useSession';
import { AppConfigInterface } from '../types/AppConfigTypes';
import { CustomReactQueryConfigInterface } from '../types/CustomReactQueryConfigTypes';
import { CustomReactQueryParams } from '../types/CustomReactQueryTypes';
import { TransportInterface } from '../types/TransportTypes';
import { TransportSearchReducerStateInterface } from '../types/TransportSearchReducerTypes';
import { getSearchQueryString } from '../utils/search';
import { transportTestData } from '../fixtures/transport';

const fetchTransportData = async (params: CustomReactQueryParams) => {
  // If we are in test mode, just return static data to work with.
  if (process.env.REACT_APP_TEST_MODE) {
    return transportTestData();
  }
  const transportSearch = params
    .queryKey[1] as TransportSearchReducerStateInterface;

  const env = params.queryKey[2];

  const headers = params.queryKey[3]
    ? (params.queryKey[3] as AxiosRequestHeaders)
    : ({} as AxiosRequestHeaders);

  const queryString = getSearchQueryString(transportSearch);

  if (env && env === 'orderEdit') {
    const res = await API.get(`/transport?${queryString}`, {
      headers,
      baseURL: `//${window.location.hostname}/api`,
    });

    return res.data;
  }

  const res = await API.get(`/transport?${queryString}`, {
    headers,
  });

  return res.data;
};

/**
 * Set up a transport query key type.
 */
type UseTransportQueryKey = [
  string,
  TransportSearchReducerStateInterface,
  string,
  AxiosRequestHeaders,
];

/**
 * Hook to get the transport tickets from the Transport API.
 *
 * @param transportSearch
 * @param config
 * @returns
 */
export const useTransport = (
  transportSearch: TransportSearchReducerStateInterface,
  appConfig: AppConfigInterface,
  header: AxiosRequestHeaders = {
    crossDomain: true,
  },
  config: CustomReactQueryConfigInterface = {},
) => {
  // Check if any config has been set. If not, apply the defaults.
  const queryConfig = config as CustomReactQueryConfigInterface;
  if (typeof config === 'undefined' || Object.keys(config).length === 0) {
    const refetchOnWindowFocus = true;
    queryConfig.refetchOnWindowFocus = refetchOnWindowFocus;

    // Set to 5 minutes.
    const staleTime = 60 * 1000 * 5;
    queryConfig.staleTime = staleTime;

    // Set to 10 minutes.
    const cacheTime = 60 * 1000 * 10;
    queryConfig.cacheTime = cacheTime;
  }

  // Don't run the query without an inbound date or outbound date.
  if (
    (!transportSearch.inbound_date
      || transportSearch.inbound_date.length <= 0)
    && (!transportSearch.outbound_date
      || transportSearch.outbound_date.length <= 0)
  ) {
    queryConfig.enabled = false;
  }

  return useQuery<
  TransportInterface,
  Error,
  TransportInterface,
  UseTransportQueryKey
  >(
    ['transport', transportSearch, appConfig.env, header],
    fetchTransportData,
    queryConfig,
  );
};

/**
 * Hook to get the transport tickets from the Transport API using a user session.
 *
 * @param transportSearch
 * @param appConfig
 * @param config
 * @returns
 */
export const useTransportWithSession = (
  transportSearch: TransportSearchReducerStateInterface,
  appConfig: AppConfigInterface,
  config: CustomReactQueryConfigInterface = {},
) => {
  // Get the session data.
  const {
    isError: sessionIsError,
    isLoading: sessionIsLoading,
    isSuccess: sessionIsSuccess,
    data: sessionData,
  } = useSession(appConfig);

  let headers = {} as AxiosRequestHeaders;
  if (
    sessionIsSuccess
    && sessionData
    && sessionData.id
  ) {
    headers = {
      Authorization: `Basic ${sessionData.id}`,
      crossDomain: true,
    } as AxiosRequestHeaders;
  }

  // Only execute the transport request if we have a session to work with.
  const queryConfig = config as CustomReactQueryConfigInterface;
  // queryConfig.enabled = !!sessionIsSuccess;

  // Get the Transport data.
  const transportQueryResults = useTransport(
    transportSearch,
    appConfig,
    headers,
    queryConfig,
  );

  const {
    isLoading: transportIsLoading,
    isSuccess: transportIsSuccess,
    isError: transportIsError,
  }: UseQueryResult<TransportInterface, Error> = transportQueryResults;

  transportQueryResults.isLoading = !!(sessionIsLoading || transportIsLoading);
  transportQueryResults.isError = !!(sessionIsError || transportIsError);
  transportQueryResults.isSuccess = !!(sessionIsSuccess && transportIsSuccess);

  return transportQueryResults;
};
