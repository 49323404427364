import React, {
  FC, useMemo, useReducer, useEffect,
} from 'react';
import { node } from 'prop-types';
// Default currency $.
import numeral from 'numeral';
// Add £.
import 'numeral/locales/en-gb';
// Add €.
import '../../locales/numeral/en-ie';
// Add A$.
import '../../locales/numeral/en-au';
import { TransportSearchContext } from '../../contexts/TransportSearchContext';
import { TransportSearchContextInterface } from '../../types/TransportSearchContextTypes';
import { ProviderInterface } from '../../types/ProviderTypes';
import TransportSearchReducer from '../../reducers/TransportSearchReducer/TransportSearchReducer';
import TransportSearchReducerInitialState from '../../reducers/TransportSearchReducer/TransportSearchReducerInitialState';
import { useHotelContext } from '../../hooks/useHotel';
import { TransportSearchActionTypes } from '../../reducers/TransportSearchReducer/TransportSearchActionTypes';
import { TransportSearchPassengersInterface } from '../../types/TransportSearchReducerTypes';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';

export const TransportSearchProvider: FC = ({ children }: ProviderInterface) => {
  // Get the app config.
  const { appConfig } = useAppConfigContext();

  // Get the hotel search settings.
  const { hotelSearch } = useHotelContext();

  // Store the transport search details in state.
  const [transportSearch, setTransportSearch] = useReducer(
    TransportSearchReducer,
    TransportSearchReducerInitialState,
  );

  // If the hotel search has been completed, extracr details of the
  // search in order to use them to find suitable transport options.
  useEffect(() => {
    if (hotelSearch) {
      // Set the travel dates.
      setTransportSearch({
        type: TransportSearchActionTypes.SET_SEARCH_DATES,
        outbound_date: hotelSearch.date_from,
        inbound_date: hotelSearch.date_to,
      });

      if (typeof hotelSearch.guests !== 'undefined') {
        // Combine all the room guests into one transport passenger object.
        const transportGuests = {
          adults: '0',
          children: '0',
          infants: '0',
          child_ages: '',
        } as TransportSearchPassengersInterface;

        // Process the hotel guests from the hotel search.
        hotelSearch.guests.forEach((guests) => {
          transportGuests.adults = (
            Number.parseInt(transportGuests.adults, 10)
            + Number.parseInt(guests.adults, 10)
          ).toString();
          transportGuests.children = (
            Number.parseInt(transportGuests.children, 10)
            + Number.parseInt(guests.children, 10)
          ).toString();
          transportGuests.infants = (
            Number.parseInt(transportGuests.infants, 10)
            + Number.parseInt(guests.infants, 10)
          ).toString();
          if (
            typeof guests.child_ages !== 'undefined'
            && guests.child_ages.length > 0
          ) {
            if (transportGuests.child_ages.length > 0) {
              transportGuests.child_ages += `, ${guests.child_ages}`;
            } else {
              transportGuests.child_ages = guests.child_ages;
            }
          }
        });

        // Set passengers.
        setTransportSearch({
          type: TransportSearchActionTypes.SET_PASSENGERS,
          adults: transportGuests.adults,
          children: transportGuests.children,
          infants: transportGuests.infants,
          child_ages: transportGuests.child_ages,
        });
      }
    }
  }, [hotelSearch]);

  // When the lang changes (initial selection), we need to update the related states.
  useEffect(() => {
    // Update the currency formatting.
    let numeralLocale = '';
    if (appConfig.lang === 'en') {
      numeralLocale = 'en-gb';
    } else if (appConfig.lang === 'de-de' || appConfig.lang === 'en-ie') {
      numeralLocale = 'en-ie';
    } else if (appConfig.lang === 'au' || appConfig.lang === 'en-au') {
      numeralLocale = 'en-au';
    }
    if (numeralLocale.length > 0) {
      numeral.locale(numeralLocale);
    } else {
      numeral.reset();
    }

    // Update the search if we are using the geo parameter.
    if (transportSearch.geo !== appConfig.lang) {
      setTransportSearch({
        type: TransportSearchActionTypes.SET_GEO,
        payload: appConfig.lang,
      });
    }
  }, [appConfig.lang]);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const transportSearchContextValue = useMemo(
    () => ({
      transportSearch,
      setTransportSearch,
    } as TransportSearchContextInterface),
    [transportSearch],
  );

  return (
    <TransportSearchContext.Provider value={transportSearchContextValue}>
      {children}
    </TransportSearchContext.Provider>
  );
};

TransportSearchProvider.propTypes = {
  children: node.isRequired,
};

TransportSearchProvider.defaultProps = {};

export default TransportSearchProvider;
