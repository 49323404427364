export enum CartActionTypes {
  SET_LOADING = 'SET_LOADING',
  SET_REFRESH = 'SET_REFRESH',
  SET_OUTBOUND_FARE_ID = 'SET_OUTBOUND_FARE_ID',
  SET_INBOUND_FARE_ID = 'SET_INBOUND_FARE_ID',
  RESET = 'RESET',
}

export type CartDefault = {
  type: '';
};

export type CartActionTypesSetLoading = {
  type: CartActionTypes.SET_LOADING;
  payload: boolean;
};

export type CartActionTypesSetRefresh = {
  type: CartActionTypes.SET_REFRESH;
  payload: boolean;
};

export type CartActionTypesSetOutboundFareId = {
  type: CartActionTypes.SET_OUTBOUND_FARE_ID;
  payload: string;
};

export type CartActionTypesSetInboundFareId = {
  type: CartActionTypes.SET_INBOUND_FARE_ID;
  payload: string;
};

export type CartActionTypesReset = {
  type: CartActionTypes.RESET;
};

export type CartActions =
  | CartDefault
  | CartActionTypesSetLoading
  | CartActionTypesSetRefresh
  | CartActionTypesSetOutboundFareId
  | CartActionTypesSetInboundFareId
  | CartActionTypesReset;
