import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {
  FareType,
  FareTypes,
  TransportDirection,
  TransportInterface,
  TransportListItemType,
  TransportLocation,
  TransportPriceFrom,
  TransportProductInterface,
} from '../types/TransportTypes';

/**
 * Return an internal key for a service + fare.
 * As the productId can contain dashes, we'll use
 * double dashes to concat the string. This will allow us to
 * split it later.
 *
 * @param productId
 * @param direction
 * @param fareTypeId
 * @returns
 */
export const getFareId = (
  productId: string,
  direction: TransportDirection,
  fareTypeId: FareTypes,
  fareGroup: number,
) => `${productId}--${direction}--${fareTypeId}--${fareGroup}`;

/**
 * Take the available fares and passenger details and
 * calculate the fare per fare type.
 *
 * @param transportProduct
 * @returns
 */
export const transportProductAvailableFares = (
  transportData: TransportInterface,
  transportProduct: TransportProductInterface,
  direction: TransportDirection,
) => {
  // Get the cheapest fare for the direction.
  let cheapestService = false;
  let cheapest: TransportPriceFrom = {
    price: '0',
    fare_group: 0,
    cheapest: false,
  };
  transportData[direction].forEach((cheapestProduct) => {
    if (cheapestProduct.price_from.cheapest) {
      if (transportProduct.id === cheapestProduct.id) {
        cheapestService = true;
      }
      cheapest = cheapestProduct.price_from;
    }
  });

  // We have assumed that fare group 0 is standard and 1 is premier.
  const fares: Array<FareType> = [];
  let standardPrice = 0;
  let premierPrice = 0;
  let availableStandard = false;
  let availablePremier = false;
  transportProduct.fares.forEach((fare) => {
    switch (fare.fare_group) {
      case 0:
        if (fare.qty > 0) {
          standardPrice += Number.parseFloat(fare.price) * fare.qty;
          // If the qty has been set, we know that this should be available.
          availableStandard = true;
        }
        break;
      case 1:
        if (fare.qty > 0) {
          premierPrice += Number.parseFloat(fare.price) * fare.qty;
          // If the qty has been set, we know that this should be available.
          availablePremier = true;
        }
        break;
      default:
        break;
    }
  });

  // Fix the number of fares to two per service; standard and premier.
  // Standard Fare.
  fares.push({
    fareId: getFareId(transportProduct.id, direction, FareTypes.standard, 0),
    typeId: FareTypes.standard,
    fareGroup: 0,
    type: 'Standard',
    price: standardPrice.toString(),
    priceDifference: (
      standardPrice - Number.parseFloat(cheapest.price)
    ).toString(),
    direct: transportProduct.direct,
    cheapest: !!((cheapestService && cheapest.fare_group === 0)),
    available: availableStandard,
  });

  // Premier Fare.
  fares.push({
    fareId: getFareId(transportProduct.id, direction, FareTypes.premier, 1),
    typeId: FareTypes.premier,
    fareGroup: 1,
    type: 'Standard Premier',
    price: premierPrice.toString(),
    priceDifference: (
      premierPrice - Number.parseFloat(cheapest.price)
    ).toString(),
    direct: transportProduct.direct,
    cheapest: !!(cheapestService && cheapest.fare_group === 1),
    available: availablePremier,
  });

  return fares;
};

/**
 * Convert minutes to an hour and minutes string.
 *
 * @param duration
 * @returns
 */
export const durationFormat = (duration: string) => {
  const hours = Math.floor(Number(duration) / 60);
  const minutes = Number(duration) % 60;
  let formattedDuration = `${hours}h`;
  if (minutes > 0) {
    formattedDuration += ` ${minutes}m`;
  }
  return formattedDuration;
};

/**
 * Get all the available services for a direction and route.
 *
 * @param transportData
 * @param direction
 * @param direct
 * @returns
 */
export const getAvailableServices = (
  transportData: TransportInterface,
  direction: TransportDirection,
) => {
  let availableTransportProducts: TransportProductInterface[] = [];
  if (transportData && typeof transportData[direction] !== 'undefined') {
    // Filter the services by direction.
    availableTransportProducts = transportData[direction];
  }
  const transportServices: TransportListItemType[] = availableTransportProducts.map(
    (transportProduct: TransportProductInterface) => {
      const fares = transportProductAvailableFares(
        transportData,
        transportProduct,
        direction,
      );

      return {
        id: transportProduct.id,
        title: transportProduct.title,
        depart: transportProduct.departure_time,
        arrive: transportProduct.arrival_time,
        duration: durationFormat(transportProduct.duration),
        changes: {
          numOfChanges: transportProduct.routes.length > 1
            ? transportProduct.routes.length - 1
            : 0,
          description: transportProduct.routes,
        },
        fares,
      };
    },
  );

  return transportServices;
};

/**
 * Determine whether a direct / indirect service is available in the Transport API response.
 *
 * @param data
 * @param direction
 * @param direct
 * @returns
 */
export const transportRouteAvailable = (
  data: TransportInterface,
  direction: TransportDirection,
) => {
  let available = false;
  let availableTransportProducts = [];
  if (typeof data !== 'undefined' && data && typeof data[direction] !== 'undefined' && data[direction]) {
    availableTransportProducts = data[direction];

    if (availableTransportProducts.length > 0) {
      available = true;
    }
  }

  return available;
};

/**
 * Get the transport list items.
 *
 * @param data
 * @return
 */
export const getTransportListItems = (data: TransportInterface) => [
  {
    id: `${TransportDirection.outbound}`,
    direction: TransportDirection.outbound,
    available: transportRouteAvailable(data, TransportDirection.outbound),
  },
  {
    id: `${TransportDirection.inbound}`,
    direction: TransportDirection.inbound,
    available: transportRouteAvailable(data, TransportDirection.inbound),
  },
];

/**
 * Map the location id to a station.
 *
 * @param locationId
 * @returns
 */
export const getTransportLocation = (locationId: string) => {
  const locations: TransportLocation[] = [
    {
      id: '7015400',
      name: 'London St Pancras',
    },
    {
      id: '8711184',
      name: 'Disneyland Paris (Marne-la-Vallee)',
    },
    {
      id: '8722326',
      name: 'Lille',
    },
  ];

  const locationData = locations.find((location) => location.id === locationId);

  return locationData?.name || '';
};

/**
 * Format a given date.
 *
 * @param {*} date
 * @param {*} format
 * @returns
 */
export const formatDate = (date: string, format = 'Do MMM YYYY') => {
  dayjs.extend(advancedFormat);
  const formattedDate = dayjs(date).format(format);
  return formattedDate;
};
