/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import '../../translations/i18n';

// When looking into test component that use translations, mocking as suggested in
// https://react.i18next.com/misc/testing does not seem to work.
// We see the error react-i18next:: You will need to pass in an i18next instance by using initReactI18next.
// If we need to wrap components in I18nextProvider for testing, it makes sense to have a HOC doing this.

// See https://stackoverflow.com/questions/68757395/how-to-make-a-functional-react-component-with-generic-type.
// See https://www.pluralsight.com/guides/higher-order-composition-typescript-react.

export function TranslateProvider<T>(Component: React.ComponentType<T>) {
  return (props: T) => (
    <I18nextProvider i18n={i18next}>
      <Component {...props} />
    </I18nextProvider>
  );
}

export default TranslateProvider;
