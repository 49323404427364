import { Reducer } from 'react';
import { AppCartInterface } from '../../types/AppCartTypes';
import { CartActions, CartActionTypes } from './CartActionTypes';
import CartReducerInitialState from './CartReducerInitialState';
import atdEvents from '../../utils/atdEvents';

const CartReducer: Reducer<AppCartInterface, CartActions> = (state, action) => {
  switch (action.type) {
    case CartActionTypes.SET_LOADING:
      atdEvents.fire('atd-transport-change-start', '');
      return {
        ...state,
        loading: action.payload,
      };
    case CartActionTypes.SET_REFRESH:
      return {
        ...state,
        refresh: action.payload,
      };
    case CartActionTypes.SET_OUTBOUND_FARE_ID:
      atdEvents.fire('atd-transport-change-finish', '');
      return {
        ...state,
        outboundFareId: action.payload,
        loading: false,
        refresh: true,
      };
    case CartActionTypes.SET_INBOUND_FARE_ID:
      atdEvents.fire('atd-transport-change-finish', '');
      return {
        ...state,
        inboundFareId: action.payload,
        loading: false,
        refresh: true,
      };
    case CartActionTypes.RESET:
      return CartReducerInitialState;
    default:
      return state;
  }
};

export default CartReducer;
