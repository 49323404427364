import React, {
  FC, useMemo, useReducer, useEffect, useRef,
} from 'react';
import { UseQueryResult } from 'react-query';
import { node } from 'prop-types';
import { useCart } from '../../hooks/useCart';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { useTransportWithSession } from '../../hooks/useTransport';
import { useTransportSearchContext } from '../../hooks/useTransportSearchContext';
import CartReducer from '../../reducers/CartReducer/CartReducer';
import CartReducerInitialState from '../../reducers/CartReducer/CartReducerInitialState';
import { AppCartContext } from '../../contexts/AppCartContext';
import { AppCartContextInterface } from '../../types/AppCartTypes';
import { CartActionTypes } from '../../reducers/CartReducer/CartActionTypes';
import { CartInterface } from '../../types/CartTypes';
import { ProviderInterface } from '../../types/ProviderTypes';
import { FareTypes, TransportDirection, TransportInterface } from '../../types/TransportTypes';
import { getFareId } from '../../utils/transport';
import { cartUpdate } from '../../utils/cart';

export const AppCartProvider: FC = ({ children }: ProviderInterface) => {
  // Make sure that the website add to cart process is ran once only.
  const addToCartAttempt = useRef(false);

  // Get the app config.
  const { appConfig } = useAppConfigContext();

  // Store the app cart in state.
  const [appCart, setAppCart] = useReducer(
    CartReducer,
    CartReducerInitialState,
  );

  // Get the refetch function so we can reload the useCart data after an interaction
  // with the Cart API.
  const { data: cartData, refetch }: UseQueryResult<CartInterface, Error> = useCart(appConfig);

  // If appCart.refresh is set to true, items have been added to the cart
  // and we need to refetch the react query cart cache.
  // In turn, this will refresh other areas that call the Cart API vias the
  // useCart hook.
  if (appCart.refresh) {
    refetch({ throwOnError: false, cancelRefetch: true });

    // Now that we have refreshed the cart, we can set the flag back.
    setAppCart({
      type: CartActionTypes.SET_REFRESH,
      payload: false,
    });
  }

  // Get the transport search to get the geo value for cart posts.
  const { transportSearch } = useTransportSearchContext();

  // Get the Transport data.
  // todo - Fix hook ordering issue when using useTransport or useTransportWithSession.
  const { data: transportData }: UseQueryResult<TransportInterface, Error> = useTransportWithSession(transportSearch, appConfig);

  // If the app reloads, we want to check for the cheapest services again
  // as the transport data response could now be different.
  useEffect(() => {
    if (appConfig.loading) {
      setAppCart({
        type: CartActionTypes.SET_OUTBOUND_FARE_ID,
        payload: '',
      });
      setAppCart({
        type: CartActionTypes.SET_INBOUND_FARE_ID,
        payload: '',
      });
    }
  }, [appConfig.loading]);

  // If we are in the Order Edit and items are not being added to the
  // cart and the app is constantly refreshed in the background while a staff member
  // performs different hotel searches. Therefore, we need to redo the included / cheapest
  // options work for each transport search.
  useEffect(() => {
    if (appConfig.env === 'orderEdit' && transportData && !appConfig.loading) {
      // Automated selected the cheapest inbound service.
      if (transportData[TransportDirection.inbound]) {
        transportData[TransportDirection.inbound].forEach((cheapestProduct) => {
          if (cheapestProduct.price_from.cheapest) {
            const newInboundFareId = getFareId(
              cheapestProduct.id,
              TransportDirection.inbound,
              cheapestProduct.price_from.fare_group
                ? FareTypes.premier
                : FareTypes.standard,
              cheapestProduct.price_from.fare_group,
            );

            // Add the cheapest inbound transport ticket.
            if (appCart.inboundFareId !== newInboundFareId) {
              setAppCart({
                type: CartActionTypes.SET_INBOUND_FARE_ID,
                payload: newInboundFareId,
              });
            }
          }
        });
      }

      // Automated selected the cheapest outbound service.
      if (transportData[TransportDirection.outbound]) {
        transportData[TransportDirection.outbound].forEach(
          (cheapestProduct) => {
            if (cheapestProduct.price_from.cheapest) {
              const newOutboundFareId = getFareId(
                cheapestProduct.id,
                TransportDirection.outbound,
                cheapestProduct.price_from.fare_group
                  ? FareTypes.premier
                  : FareTypes.standard,
                cheapestProduct.price_from.fare_group,
              );

              // Add the cheapest outbound transport ticket.
              if (appCart.outboundFareId !== newOutboundFareId) {
                setAppCart({
                  type: CartActionTypes.SET_OUTBOUND_FARE_ID,
                  payload: newOutboundFareId,
                });
              }
            }
          },
        );
      }
    }
  }, [appConfig, transportData]);

  /**
   * On loading the app, add the fares to the appCart.
   * If there are no transport items in the cart, add the cheapest automatically.
   * We only want this hook to run once
   */
  useEffect(() => {
    // If the app is in the default environment, it will interact with
    // the Cart API.
    const defaultEnv = process.env.REACT_APP_DEFAULT_ENV
      ? process.env.REACT_APP_DEFAULT_ENV
      : 'web';

    if (
      !appConfig.loading
      && !addToCartAttempt.current
      && appConfig.env
      && appConfig.env === defaultEnv
      && transportData
      && cartData
      && cartData.data.tickets
    ) {
      // Limit this process to once per app load and once everything is loaded.
      addToCartAttempt.current = true;

      let inboundFareId = '';
      let outboundFareId = '';
      // Check the cart for outbound and inbound transport tickets.
      // If the app is refreshed, this will popuulate the app cart.
      cartData.data.tickets.forEach((ticket) => {
        if (
          ticket.data.direction
          && ticket.data.direction === TransportDirection.inbound
        ) {
          inboundFareId = getFareId(
            ticket.data.transport_product_id,
            ticket.data.direction,
            ticket.data.fare_type_id,
            ticket.data.fare_group,
          );
        } else if (
          ticket.data.direction
          && ticket.data.direction === TransportDirection.outbound
        ) {
          outboundFareId = getFareId(
            ticket.data.transport_product_id,
            ticket.data.direction,
            ticket.data.fare_type_id,
            ticket.data.fare_group,
          );
        }
      });
      if (
        (!appCart.inboundFareId && inboundFareId)
        || (!appCart.outboundFareId && outboundFareId)
      ) {
        // Set state to loading as we are updating the app cart.
        setAppCart({
          type: CartActionTypes.SET_LOADING,
          payload: true,
        });
      }

      if (
        (!appCart.inboundFareId && inboundFareId)
        || (inboundFareId && appCart.inboundFareId !== inboundFareId)
      ) {
        // An inbound transport ticket is in the cart so update the app cart with the details.
        setAppCart({
          type: CartActionTypes.SET_INBOUND_FARE_ID,
          payload: inboundFareId,
        });
      } else if (!inboundFareId && transportData[TransportDirection.inbound]) {
        // No inbound transport ticket is in the cart so add the cheapest one automatically.
        transportData[TransportDirection.inbound].forEach((cheapestProduct) => {
          if (cheapestProduct.price_from.cheapest) {
            const newInboundFareId = getFareId(
              cheapestProduct.id,
              TransportDirection.inbound,
              cheapestProduct.price_from.fare_group
                ? FareTypes.premier
                : FareTypes.standard,
              cheapestProduct.price_from.fare_group,
            );

            // Set state to loading as we are updating the app cart.
            setAppCart({
              type: CartActionTypes.SET_LOADING,
              payload: true,
            });

            // Add the cheapest inbound transport ticket.
            cartUpdate(
              transportData,
              TransportDirection.inbound,
              newInboundFareId,
              transportSearch.geo,
            ).then((update) => {
              if (update.success) {
                setAppCart({
                  type: CartActionTypes.SET_INBOUND_FARE_ID,
                  payload: newInboundFareId,
                });
              } else if (update.error) {
                // In the event of an error, stop the loading action
                // so that a further add attempt can be made.
                setAppCart({
                  type: CartActionTypes.SET_LOADING,
                  payload: false,
                });
              }
            });
          }
          return true;
        });
      }

      if (
        (!appCart.outboundFareId && outboundFareId)
        || (outboundFareId && appCart.outboundFareId !== outboundFareId)
      ) {
        // An inbound transport ticket is in the cart so update the app cart with the details.
        setAppCart({
          type: CartActionTypes.SET_OUTBOUND_FARE_ID,
          payload: outboundFareId,
        });
      } else if (
        !outboundFareId
        && transportData[TransportDirection.outbound]
      ) {
        // No inbound transport ticket is in the cart so add the cheapest one automatically.
        transportData[TransportDirection.outbound].forEach(
          (cheapestProduct) => {
            if (cheapestProduct.price_from.cheapest) {
              const newOutboundFareId = getFareId(
                cheapestProduct.id,
                TransportDirection.outbound,
                cheapestProduct.price_from.fare_group
                  ? FareTypes.premier
                  : FareTypes.standard,
                cheapestProduct.price_from.fare_group,
              );

              // Set state to loading as we are updating the app cart.
              setAppCart({
                type: CartActionTypes.SET_LOADING,
                payload: true,
              });

              // Add the cheapest inbound transport ticket.
              cartUpdate(
                transportData,
                TransportDirection.outbound,
                newOutboundFareId,
                transportSearch.geo,
              ).then((update) => {
                if (update.success) {
                  setAppCart({
                    type: CartActionTypes.SET_OUTBOUND_FARE_ID,
                    payload: newOutboundFareId,
                  });
                } else if (update.error) {
                  // In the event of an error, stop the loading action
                  // so that a further add attempt can be made.
                  setAppCart({
                    type: CartActionTypes.SET_LOADING,
                    payload: false,
                  });
                }
              });
            }
          },
        );
      }
    }
  }, [cartData, transportData, appConfig]);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const appCartContextValue = useMemo(
    () => ({
      appCart,
      setAppCart,
    } as AppCartContextInterface),
    [appCart],
  );

  return (
    <AppCartContext.Provider value={appCartContextValue}>
      {children}
    </AppCartContext.Provider>
  );
};

AppCartProvider.propTypes = {
  children: node.isRequired,
};

AppCartProvider.defaultProps = {};

export default AppCartProvider;
