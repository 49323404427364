import { TransportSearchReducerStateInterface } from '../../types/TransportSearchReducerTypes';

const TransportSearchReducerInitialState = {
  cached: process.env.REACT_APP_API_CACHED_REQUESTS
    ? process.env.REACT_APP_API_CACHED_REQUESTS
    : 1,
  view: 'website',
  outbound_date: '',
  inbound_date: '',
  origin: '',
  destination: '',
  adults: '',
  children: '',
  infants: '',
  child_ages: '',
  transport_type_id: '',
  geo: 'en',
} as TransportSearchReducerStateInterface;

export default TransportSearchReducerInitialState;
