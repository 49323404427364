import { useQuery } from 'react-query';
import { AppConfigInterface } from '../types/AppConfigTypes';
import { CustomReactQueryConfigInterface } from '../types/CustomReactQueryConfigTypes';
import { SessionInterface } from '../types/SessionTypes';
import { CustomReactQueryParams } from '../types/CustomReactQueryTypes';
import API from '../api/phx';

const fetchSessionData = async (params: CustomReactQueryParams) => {
  const env = params.queryKey[2];

  if (env === 'orderEdit') {
    const res = await API.get('/session', {
      baseURL: `//${window.location.hostname}/api`,
    });
    return res.data;
  }

  const lang = params.queryKey[1];
  const res = await API.get(`/session?geo=${lang}`);
  return res.data;
};

/**
 * Set up a session query key type.
 */
type UseSessionQueryKey = [
  string,
  string,
  string,
];

/**
 * Hook to get session data from the session API.
 *
 * @param appConfig
 * @param config
 * @returns
 */
export const useSession = (
  appConfig: AppConfigInterface,
  config: CustomReactQueryConfigInterface = {},
) => {
  const lang = appConfig.lang;

  // Check if any config has been set. If not, apply the defaults.
  const queryConfig = config as CustomReactQueryConfigInterface;
  if (typeof config === 'undefined' || Object.keys(config).length === 0) {
    const refetchOnWindowFocus = true;
    // Set to 5 minutes.
    const staleTime = 60 * 1000 * 5;
    // Set to 10 minutes.
    const cacheTime = 60 * 1000 * 10;

    queryConfig.refetchOnWindowFocus = refetchOnWindowFocus;
    queryConfig.staleTime = staleTime;
    queryConfig.cacheTime = cacheTime;
  }

  return useQuery<
  SessionInterface,
  Error,
  SessionInterface,
  UseSessionQueryKey
  >(['session', lang, appConfig.env], fetchSessionData, queryConfig);
};

export default useSession;
