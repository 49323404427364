import React, {
  FC,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { node } from 'prop-types';
import { AppConfigContext } from '../../contexts/AppConfigContext';
import { AppConfigInterface } from '../../types/AppConfigTypes';
import { AppConfigContextInterface } from '../../types/AppConfigContextTypes';
import { ProviderInterface } from '../../types/ProviderTypes';
import WebSettings from '../../utils/webSettings';

// Define the order edit object.
declare global {
  interface Window {
    // Currently, we don't need to define this object, just reference it's existance.
    OrderEdit: unknown;
  }
}

export const AppConfigProvider: FC = ({ children }: ProviderInterface) => {
  // Get any set up settings.
  const webSettings = new WebSettings();
  // Store the app config in state.
  const [appConfig, setAppConfig] = useState({
    lang: webSettings.lang,
    loading: true,
    env: '',
  } as AppConfigInterface);

  // Determine which environment the app is sitting in.
  useEffect(() => {
    const defaultEnv = process.env.REACT_APP_DEFAULT_ENV
      ? process.env.REACT_APP_DEFAULT_ENV
      : 'web';

    // If the order edit environment is detected, we need to change
    // the reference to the environment that the app sits within. There will
    // be different interactions and expectations that we need to manage.
    if (window.OrderEdit) {
      if (appConfig.env !== 'orderEdit') {
        setAppConfig((prevState) => ({
          ...prevState,
          env: 'orderEdit',
        }));
      }
    } else if (!appConfig.env || appConfig.env !== defaultEnv) {
      setAppConfig((prevState) => ({
        ...prevState,
        env: defaultEnv,
      }));
    }
  }, [window.OrderEdit]);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const transportSearchContextValue = useMemo(
    () => ({
      appConfig,
      setAppConfig,
    } as AppConfigContextInterface),
    [appConfig],
  );

  return (
    <AppConfigContext.Provider value={transportSearchContextValue}>
      {children}
    </AppConfigContext.Provider>
  );
};

AppConfigProvider.propTypes = {
  children: node.isRequired,
};

AppConfigProvider.defaultProps = {};

export default AppConfigProvider;
