/**
 * Define the two available options.
 */
export enum TransportDirection {
  'inbound' = 'inbound',
  'outbound' = 'outbound',
}

/**
 * Define the route type.
 */
export type TransportRoute = {
  carrier: string;
  origin: string;
  destination: string;
  duration: string;
  departure_date: string;
  departure_time: string;
  arrival_date: string;
  arrival_time: string;
};

/**
 * Define the change type.
 */
export type ChangesType = {
  numOfChanges: number;
  description: TransportRoute[];
};

/**
 * Define the possible fare types.
 */
export enum FareTypes {
  'standard' = 'standard',
  'premier' = 'premier',
}

/**
 * Define the fare type.
 */
export type FareType = {
  fareId: string;
  typeId: FareTypes;
  fareGroup: number;
  type: string;
  priceDifference: string;
  price: string;
  direct: boolean;
  cheapest: boolean;
  available: boolean;
};

/**
 * Define the transport list item type.
 */
export type TransportListItemType = {
  id: string;
  title: string;
  depart: string;
  arrive: string;
  duration: string;
  changes: ChangesType;
  fares: FareType[];
};

/**
 * Define the transport list type.
 */
export type TransportListItem = {
  id: string;
  direction: TransportDirection;
  available: boolean;
};

/**
 * Transport Fare Price Options.
 */
export type TransportFarePriceOptions = {
  price_option_id: string;
  price: string;
};

/**
 * Transport Price From.
 */
export type TransportPriceFrom = {
  price: string;
  fare_group: number;
  cheapest: boolean;
};

/**
 * Define the fare attribute (fields being used).
 */
export type FareAttribute = {
  attribute_id: string;
  values: (string | number)[];
  locked?: boolean;
  title?: string;
  type?: string;
  format?: string;
  required?: string;
  required_for_booking?: string;
  per_qty?: string;
  tip?: string;
  backend_only?: string;
  calendar_based?: string;
  availability_product_id?: string;
  availability_ticket_id?: string;
};

/**
 * Transport Fare.
 */
export interface TransportFare {
  fare_id: string;
  ticket_id: string;
  title: string;
  sku: string;
  fare_type: string;
  fare_group: number;
  passenger_type: string;
  min_age: number;
  max_age: number;
  qty: number;
  date_id: string;
  description: string;
  supplier_api_code?: string;
  supplier_live_price?: string | number;
  supplier_api_provider?: string;
  booking_type: string;
  cost: string;
  price: string;
  rrp: string;
  price_options: TransportFarePriceOptions[];
  class: string;
  attributes: FareAttribute[];
  supplier_info: unknown;
}

/**
 * Transport Product Items.
 */
export interface TransportProductInterface {
  id: string;
  product_id: string;
  title: string;
  carrier: string;
  transport_type_id: string;
  departure_date: string;
  departure_time: string;
  arrival_date: string;
  arrival_time: string;
  duration: string;
  direct: boolean;
  fares: TransportFare[];
  routes: TransportRoute[];
  price_from: TransportPriceFrom;
  exchange_rate: number;
  cost_cur: string;
}

/**
 * Define the passengers type.
 */
export interface TransportPassengersType {
  adults: number;
  children: number;
  infants: number;
  child_ages: number[];
}

/**
 * Initial transport interface.
 */
export interface TransportInterface {
  outbound_date: string;
  inbound_date: string;
  origin: string;
  destination: string;
  sale_cur: string;
  transport_type_id: string;
  passengers: TransportPassengersType;
  outbound: TransportProductInterface[];
  inbound: TransportProductInterface[];
}

/**
 * Transport location type.
 */
export type TransportLocation = {
  id: string;
  name: string;
};
