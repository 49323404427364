import dayjs from 'dayjs';
import { HotelInterface } from '../types/HotelTypes';

/**
 * Static hotel search data for test purposes.
 */
export const hotelSearchTestData = () => ({
  date_from: dayjs().add(1, 'month').format('YYYY-MM-DD'),
  date_to: dayjs().add(1, 'month').add(2, 'day').format('YYYY-MM-DD'),
  guests: [
    {
      adults: '2',
      children: '0',
      infants: '0',
      child_ages: '',
    },
  ],
} as HotelInterface);

export default hotelSearchTestData;
