import React, { FC } from 'react';
import styles from './Spinner.module.css';

export const Spinner: FC = () => (
  <div className={styles.spinnerOverlay}>
    <div className={styles.spinnerContainer} />
  </div>
);

export default Spinner;
