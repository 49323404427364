import { Reducer } from 'react';
import { TransportSearchReducerStateInterface } from '../../types/TransportSearchReducerTypes';
import {
  TransportSearchActions,
  TransportSearchActionTypes,
} from './TransportSearchActionTypes';
import TransportSearchReducerInitialState from './TransportSearchReducerInitialState';

const TransportSearchReducer: Reducer<
TransportSearchReducerStateInterface,
TransportSearchActions
> = (state, action) => {
  switch (action.type) {
    case TransportSearchActionTypes.SET_CACHE:
      return {
        ...state,
        cached: action.payload,
      };
    case TransportSearchActionTypes.SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    case TransportSearchActionTypes.SET_FIELDS:
      return {
        ...state,
        fields: action.payload,
      };
    case TransportSearchActionTypes.SET_DATE_OUTBOUND:
      return {
        ...state,
        outbound_date: action.payload,
      };
    case TransportSearchActionTypes.SET_DATE_INBOUND:
      return {
        ...state,
        inbound_date: action.payload,
      };
    case TransportSearchActionTypes.SET_SEARCH_DATES:
      return {
        ...state,
        outbound_date: action.outbound_date,
        inbound_date: action.inbound_date,
      };
    case TransportSearchActionTypes.SET_ORIGIN:
      return {
        ...state,
        origin: action.payload,
      };
    case TransportSearchActionTypes.SET_DESTINATION:
      return {
        ...state,
        destination: action.payload,
      };
    case TransportSearchActionTypes.SET_PASSENGERS:
      return {
        ...state,
        adults: action.adults,
        children: action.children,
        infants: action.infants,
        child_ages: action.child_ages,
      };
    case TransportSearchActionTypes.SET_ADULTS:
      return {
        ...state,
        adults: action.payload,
      };
    case TransportSearchActionTypes.SET_CHILDREN:
      return {
        ...state,
        children: action.payload,
      };
    case TransportSearchActionTypes.SET_INFANTS:
      return {
        ...state,
        infants: action.payload,
      };
    case TransportSearchActionTypes.SET_CHILD_AGES:
      return {
        ...state,
        child_ages: action.payload,
      };
    case TransportSearchActionTypes.SET_TRANSPORT_TYPE_ID:
      return {
        ...state,
        transport_type_id: action.payload,
      };
    case TransportSearchActionTypes.SET_GEO:
      return {
        ...state,
        geo: action.payload,
      };
    case TransportSearchActionTypes.RESET_FILTERS:
      return TransportSearchReducerInitialState;
    default:
      return state;
  }
};

export default TransportSearchReducer;
