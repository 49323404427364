import React, {
  FC, useMemo, useState, useEffect,
} from 'react';
import { node } from 'prop-types';
import { HotelContext } from '../../contexts/HotelContext';
import {
  HotelGuestInterface,
  HotelInterface,
} from '../../types/HotelTypes';
import { HotelContextInterface } from '../../types/HotelContextTypes';
import { ProviderInterface } from '../../types/ProviderTypes';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { hotelSearchTestData } from '../../fixtures/hotelSearch';

export const HotelProvider: FC = ({ children }: ProviderInterface) => {
  // Get the app config.
  const { appConfig, setAppConfig } = useAppConfigContext();

  // Store the hotel search details in state.
  const [hotelSearch, setHotelSearch] = useState({} as HotelInterface);

  useEffect(() => {
    if (appConfig.loading === true) {
      // Get the hotel search data from local storage and add it to the hotel search state.
      const lsHotelSearch = localStorage.getItem('hotel_search');
      if (lsHotelSearch) {
        const params = JSON.parse(lsHotelSearch);
        if (
          typeof params !== 'undefined'
          && typeof params.date_from !== 'undefined'
        ) {
          // Restructure the data so that it is TypeScript friendly.
          const hotelGuests: Array<HotelGuestInterface> = [];
          let room: number | undefined;
          let paramName: string;
          Object.keys(params).forEach((param) => {
            if (
              param.includes('adults')
              || param.includes('children')
              || param.includes('infants')
              || param.includes('child_ages')
            ) {
              // Get the room number.
              room = Number.parseInt(param.replace(/\D/g, ''), 10);
              // If there are no numbers in the param, it is the
              // default first room of 0.
              if (
                typeof room === 'undefined'
                || typeof room !== 'number'
                || Number.isNaN(room)
              ) {
                room = 0;
              } else {
                // The second room starts from 2 so we need to subtract 1.
                room -= 1;
              }

              // Get the param name.
              paramName = param.replace(/\d+/g, '').toString();

              // Declare room for guests.
              if (typeof hotelGuests[room] === 'undefined') {
                hotelGuests[room] = {
                  adults: '0',
                  children: '0',
                  infants: '0',
                  child_ages: '',
                } as HotelGuestInterface;
              }

              // Update the guest parameter, limited to the expected parameters.
              hotelGuests[room][
                paramName as 'adults' | 'children' | 'infants' | 'child_ages'
              ] = params[param].trim();
            }
          });

          const hotelSearchData = {
            date_from: params.date_from,
            date_to: params.date_to,
            guests: hotelGuests,
          } as HotelInterface;

          setHotelSearch(hotelSearchData);
        }
      } else if (process.env.REACT_APP_TEST_MODE) {
        // If in test mode, use static fixture data to set the hotel search state.
        const hotelSearchData = hotelSearchTestData();
        setHotelSearch(hotelSearchData);
      }

      setAppConfig((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  }, [appConfig]);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const transportSearchContextValue = useMemo(
    () => ({
      hotelSearch,
      setHotelSearch,
    } as HotelContextInterface),
    [hotelSearch],
  );

  return (
    <HotelContext.Provider value={transportSearchContextValue}>
      {children}
    </HotelContext.Provider>
  );
};

HotelProvider.propTypes = {
  children: node.isRequired,
};

HotelProvider.defaultProps = {};

export default HotelProvider;
