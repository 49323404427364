import React, { FC, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppCartProvider } from './providers/AppCartProvider';
import { AppConfigProvider } from './providers/AppConfigProvider';
import { HotelProvider } from './providers/HotelProvider';
import { TransportSearchProvider } from './providers/TransportSearchProvider';
import { TranslateProvider } from './providers/TranslateProvider';
import { Spinner } from './components/Spinner';

// Lazy load the app body.
const LazyLayout = React.lazy(() => import('./components/Layout').then(({ Layout }) => ({ default: Layout })));

// Initialise React Query Client.
const queryClient = new QueryClient();

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <Suspense fallback={<Spinner />}>
      <AppConfigProvider>
        <HotelProvider>
          <TransportSearchProvider>
            <AppCartProvider>
              <LazyLayout />
            </AppCartProvider>
          </TransportSearchProvider>
        </HotelProvider>
      </AppConfigProvider>
    </Suspense>
    {process.env.NODE_ENV === 'development' && (
      <ReactQueryDevtools initialIsOpen={false} />
    )}
  </QueryClientProvider>
);

// Set up translations for the App via HOC.
export const AppWithTranslations = TranslateProvider(App);

export default App;
