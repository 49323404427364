export enum TransportSearchActionTypes {
  SET_CACHE = 'SET_CACHE',
  SET_VIEW = 'SET_VIEW',
  SET_FIELDS = 'SET_FIELDS',
  SET_DATE_OUTBOUND = 'SET_DATE_OUTBOUND',
  SET_DATE_INBOUND = 'SET_DATE_INBOUND',
  SET_SEARCH_DATES = 'SET_SEARCH_DATES',
  SET_ORIGIN = 'SET_ORIGIN',
  SET_DESTINATION = 'SET_DESTINATION',
  SET_PASSENGERS = 'SET_PASSENGERS',
  SET_ADULTS = 'SET_ADULTS',
  SET_CHILDREN = 'SET_CHILDREN',
  SET_INFANTS = 'SET_INFANTS',
  SET_CHILD_AGES = 'SET_CHILD_AGES',
  SET_TRANSPORT_TYPE_ID = 'SET_TRANSPORT_TYPE_ID',
  SET_GEO = 'SET_GEO',
  RESET_FILTERS = 'RESET_FILTERS',
}

export type TransportSearchDefault = {
  type: '';
};

export type TransportSearchActionTypesSetCache = {
  type: TransportSearchActionTypes.SET_CACHE;
  payload: number | string;
};

export type TransportSearchActionTypesSetView = {
  type: TransportSearchActionTypes.SET_VIEW;
  payload: string;
};

export type TransportSearchActionTypesSetFields = {
  type: TransportSearchActionTypes.SET_FIELDS;
  payload: string[];
};

export type TransportSearchActionTypesSetDateOutbound = {
  type: TransportSearchActionTypes.SET_DATE_OUTBOUND;
  payload: string;
};

export type TransportSearchActionTypesSetDateInbound = {
  type: TransportSearchActionTypes.SET_DATE_INBOUND;
  payload: string;
};

export type TransportSearchActionTypesSetSearchDates = {
  type: TransportSearchActionTypes.SET_SEARCH_DATES;
  outbound_date: string;
  inbound_date: string;
};

export type TransportSearchActionTypesSetOrigin = {
  type: TransportSearchActionTypes.SET_ORIGIN;
  payload: string;
};

export type TransportSearchActionTypesSetDestination = {
  type: TransportSearchActionTypes.SET_DESTINATION;
  payload: string;
};

export type TransportSearchActionTypesSetPassengers = {
  type: TransportSearchActionTypes.SET_PASSENGERS;
  adults: string;
  children: string;
  infants: string;
  child_ages: string;
};

export type TransportSearchActionTypesSetAdults = {
  type: TransportSearchActionTypes.SET_ADULTS;
  payload: string;
};

export type TransportSearchActionTypesSetChildren = {
  type: TransportSearchActionTypes.SET_CHILDREN;
  payload: string;
};

export type TransportSearchActionTypesSetInfants = {
  type: TransportSearchActionTypes.SET_INFANTS;
  payload: string;
};

export type TransportSearchActionTypesSetChildAges = {
  type: TransportSearchActionTypes.SET_CHILD_AGES;
  payload: string;
};

export type TransportSearchActionTypesSetTransportTypeId = {
  type: TransportSearchActionTypes.SET_TRANSPORT_TYPE_ID;
  payload: string;
};

export type TransportSearchActionTypesSetGeo = {
  type: TransportSearchActionTypes.SET_GEO;
  payload: string;
};

export type TransportSearchActionTypesResetFilters = {
  type: TransportSearchActionTypes.RESET_FILTERS;
};

export type TransportSearchActions =
  | TransportSearchDefault
  | TransportSearchActionTypesSetCache
  | TransportSearchActionTypesSetView
  | TransportSearchActionTypesSetFields
  | TransportSearchActionTypesSetDateOutbound
  | TransportSearchActionTypesSetDateInbound
  | TransportSearchActionTypesSetSearchDates
  | TransportSearchActionTypesSetOrigin
  | TransportSearchActionTypesSetDestination
  | TransportSearchActionTypesSetPassengers
  | TransportSearchActionTypesSetAdults
  | TransportSearchActionTypesSetChildren
  | TransportSearchActionTypesSetInfants
  | TransportSearchActionTypesSetChildAges
  | TransportSearchActionTypesSetTransportTypeId
  | TransportSearchActionTypesSetGeo
  | TransportSearchActionTypesResetFilters;
