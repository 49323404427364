/** Add a cross-browser, dependency-free events interface
 * so fetched items can communicate with each other. */
const atdEvents = (function () {
  return {
    fire(name: string, data: any) {
      let ev;
      if (CustomEvent !== null) {
        ev = new CustomEvent(name, { bubbles: true, cancelable: true, detail: data });
        document.dispatchEvent(ev);
      } else {
        ev = document.createEvent('ev');
        ev.initEvent(name, true, true); // Can bubble, and is cancellable.
        (<any>ev).detail = data;
        document.dispatchEvent(ev);
      }
    },

    listen(name: any, callback: (this: Document, ev: any) => any) {
      document.addEventListener(name, callback);
    },

    unlisten(name: any, callback: (this: Document, ev: any) => any) {
      document.removeEventListener(name, callback);
    },
  };
}());

export default atdEvents;
