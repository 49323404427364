import { WebSettingsInterface } from '../types/WebSettingsTypes';

// Define the global variable drupalSettings.
declare global {
  interface Window {
    drupalSettings: unknown;
  }
}

// Define the structure of the settings.
type D8Settings = {
  path: {
    currentLanguage: string;
  };
};

/**
 * Web Settings Class.
 *
 * Load all the settings from a hosting system such as Drupal.
 */
class WebSettings implements WebSettingsInterface {
  lang: string;

  constructor() {
    this.lang = process.env.REACT_APP_DEFAULT_LANG
      ? process.env.REACT_APP_DEFAULT_LANG
      : 'en';
    this.getWebSettings();
  }

  private getWebSettings() {
    // Drupal 8 / 9 settings.
    if (typeof window.drupalSettings !== 'undefined') {
      const webSettings = window.drupalSettings as D8Settings;
      if (
        typeof webSettings !== 'undefined'
        && typeof webSettings.path !== 'undefined'
        && typeof webSettings.path.currentLanguage !== 'undefined'
        && webSettings.path.currentLanguage.length > 0
      ) {
        this.lang = webSettings.path.currentLanguage;
      }
    }
  }
}

export default WebSettings;
