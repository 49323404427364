import { TransportSearchReducerStateInterface } from '../types/TransportSearchReducerTypes';

type SearchQueryKeys =
  'cached'
  | 'view'
  | 'fields'
  | 'outbound_date'
  | 'inbound_date'
  | 'origin'
  | 'destination'
  | 'adults'
  | 'children'
  | 'infants'
  | 'child_ages'
  | 'transport_type_id'
  | 'geo';

export const getSearchQueryString = (
  search: TransportSearchReducerStateInterface,
  exclude: string[] = [],
) => Object.keys(search)
  .filter(
    (key) => !exclude.includes(key)
        && typeof search[key as SearchQueryKeys] !== 'undefined',
  )
  .map((key) => {
    let searchQuery = '';
    if (typeof search[key as SearchQueryKeys] !== 'undefined') {
      if (key === 'fields') {
        const searchFields = search[key as SearchQueryKeys] as string[];
        const fields = searchFields.map((field: string) => field).join(',');
        searchQuery = `${key}=${fields}`;
      } else {
        searchQuery = `${key}=${search[key as SearchQueryKeys]}`;
      }
    }

    return searchQuery;
  })
  .join('&') as string;

export default getSearchQueryString;
