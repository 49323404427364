import { AppCartInterface } from '../../types/AppCartTypes';

const CartReducerInitialState = {
  refresh: false,
  loading: false,
  outboundFareId: '',
  inboundFareId: '',
} as AppCartInterface;

export default CartReducerInitialState;
