import { useQuery } from 'react-query';
import { AppConfigInterface } from '../types/AppConfigTypes';
import { CustomReactQueryConfigInterface } from '../types/CustomReactQueryConfigTypes';
import { CartInterface } from '../types/CartTypes';
import { CustomReactQueryParams } from '../types/CustomReactQueryTypes';
import API from '../api/phx';
import { cartTestData } from '../fixtures/cart';

const fetchCartData = async (params: CustomReactQueryParams) => {
  // If we are in test mode, just return static data to work with.
  if (process.env.REACT_APP_TEST_MODE) {
    return cartTestData();
  }
  const env = params.queryKey[2];
  if (env && env !== 'orderEdit') {
    const lang = params.queryKey[1];
    const res = await API.get(
      `/cart?geo=${lang}&view=website&tags=transport-tickets`,
    );
    return res.data;
  }

  return {};
};

/**
 * Set up a cart query key type.
 */
type UseCartQueryKey = [string, string, string];

/**
 * Hook to get cart data from the cart API.
 *
 * @param appConfig
 * @param config
 * @returns
 */
export const useCart = (
  appConfig: AppConfigInterface,
  config: CustomReactQueryConfigInterface = {},
) => {
  const lang = appConfig.lang;
  // Check if any config has been set. If not, apply the defaults.
  const queryConfig = config as CustomReactQueryConfigInterface;
  if (typeof config === 'undefined' || Object.keys(config).length === 0) {
    const refetchOnWindowFocus = true;
    queryConfig.refetchOnWindowFocus = refetchOnWindowFocus;

    // Do not cache cart requests.
    const cacheTime = 0;
    queryConfig.cacheTime = cacheTime;

    // Set record to always stale.
    const staleTime = 0;
    queryConfig.staleTime = staleTime;
  }
  return useQuery<CartInterface, Error, CartInterface, UseCartQueryKey>(
    ['cart', lang, appConfig.env],
    fetchCartData,
    queryConfig,
  );
};

export default useCart;
