import { CartInterface, CartTicketType } from '../types/CartTypes';
import {
  FareTypes, TransportDirection, TransportInterface, TransportProductInterface,
} from '../types/TransportTypes';
import API from '../api/phx';

// Define cartSummaryRefresh from atd_hotels_app_cart_interaction.
declare global {
  interface Window {
    atd_hotels_app_cart_interaction: {
      cartSummaryRefresh: () => void;
    };
  }
}

/**
 * Given the selected fare, build an add to cart object.
 *
 * @param transportData
 * @param direction
 * @param selectedFareId
 * @returns
 */
export const buildCartItems = (
  transportData: TransportInterface,
  direction: TransportDirection,
  selectedFareId: string,
) => {
  const transportProducts: Array<TransportProductInterface> = transportData[direction];

  // Break down the fareId. This is constructed using the getFareId function.
  // We have double dashes as the productId contains single dashes.
  const fareDetails = selectedFareId.split('--');
  const productId = fareDetails[0];
  // const direction = fareDetails[1];
  // const fareTypeId = fareDetails[2];
  const fareGroup = Number(fareDetails[3]);

  const cartItems = transportProducts
    .filter((transportProduct) => transportProduct.id === productId)
    .map((transportProduct) => transportProduct.fares
      .filter((fare) => Number(fare.fare_group) === fareGroup && fare.qty > 0)
      .map((fare) => {
        let fareTypeId: FareTypes = FareTypes.standard;
        if (
          fare.fare_type === FareTypes.standard
          || fare.fare_type === FareTypes.premier
        ) {
          fareTypeId = fare.fare_type;
        } else if (fare.fare_group) {
          switch (fare.fare_group) {
            case 0:
              fareTypeId = FareTypes.standard;
              break;
            case 1:
              fareTypeId = FareTypes.premier;
              break;
            default:
              break;
          }
        }
        return {
          ticket_id: fare.ticket_id,
          qty: fare.qty,
          tags: [
            'hotels',
            'transport-tickets',
            `${direction}-transport-tickets`,
          ],
          attributes: {
            5: [transportProduct.departure_date],
            10: [transportProduct.departure_time],
            12: [transportProduct.arrival_date],
            24: [transportData.passengers.child_ages.join(',')],
            48: [transportData.passengers.adults],
            49: [transportData.passengers.children],
            57: [transportProduct.arrival_time],
            87: [transportData.passengers.infants],
          },
          date_id: fare.date_id,
          data: {
            outbound_date: transportData.outbound_date,
            inbound_date: transportData.inbound_date,
            transport_product_id: transportProduct.id,
            carrier: transportProduct.carrier,
            duration: transportProduct.duration,
            direction,
            origin: transportData.origin,
            destination: transportData.destination,
            transport_type_id: transportProduct.transport_type_id,
            attraction_id: transportProduct.transport_type_id,
            fare_type_id: fareTypeId,
            fare_group: fare.fare_group,
            supplier_info: fare.supplier_info,
          },
        };
      }));

  return {
    items: cartItems[0] ? cartItems[0] : {},
  };
};

export const cartUpdate = async (
  data: TransportInterface,
  direction: TransportDirection,
  selectedFareId: string,
  geo: string | undefined,
) => {
  // Skip the add to cart functionality in test mode. It is being implemented to support theming work.
  if (process.env.REACT_APP_TEST_MODE) {
    return {
      success: true,
      error: false,
      loading: false,
    };
  }
  // Construct the cart request.
  const cartItems = buildCartItems(data, direction, selectedFareId);

  if (cartItems) {
    // Firstly delete the current tickets for a given direction.
    return API.delete(
      `/cart?returnGet=1&view=website&tag=${direction.toLocaleLowerCase()}-transport-tickets&geo=${geo}`,
    )
      .then(() => API
        .post(
          `/cart?returnGet=1&view=website&geo=${geo}`,
          cartItems,
        )
        .then(() => {
          // Use the app cart interaction functions to update items on the Phx page.
          if (typeof window.atd_hotels_app_cart_interaction !== 'undefined') {
            window.atd_hotels_app_cart_interaction.cartSummaryRefresh();
          }

          return {
            success: true,
            error: false,
            loading: false,
          };
        })
        .catch((error: Error) => {
          /* eslint-disable no-debugger, no-console */
          console.error(
            `There was an error, ${error}, when trying to submit the tickets to the cart`,
          );
          /* eslint-disable no-debugger, no-console */
          return {
            success: false,
            error: true,
            loading: false,
          };
        }))
      .catch((error: Error) => {
        /* eslint-disable no-debugger, no-console */
        console.error(
          `There was an error, ${error}, when trying to delete the tickets from the cart`,
        );
        /* eslint-disable no-debugger, no-console */

        return {
          success: false,
          error: true,
          loading: false,
        };
      });
  }

  return {
    success: false,
    error: false,
    loading: true,
  };
};

/**
 * Get the total for the transport tickets in the cart.
 * This information is required to display the price difference
 * for each available transport service.
 *
 * @param cartData
 * @returns
 */
export const getCartTotal = (cartData: CartInterface) => {
  // The Cart API request via the useCart hook should only be requesting
  // transport tickets so we just need to go through the response
  // and add up all the prices * qty.
  const cartTotal = cartData.data.tickets.reduce(
    (partialTotal: number, ticket: CartTicketType) => partialTotal + Number.parseFloat(ticket.price) * Number(ticket.qty),
    0,
  );

  return cartTotal;
};
