import { CartInterface } from '../types/CartTypes';

/**
 * Data that can be used for testing.
 *
 * todo - Use transport tickets.
 * @returns
 */
export const cartTestData = () => ({
  meta: {
    count: 4,
    total_count: 4,
    limit: 0,
    offset: 0,
    total_price: '620.00',
    sale_cur: 'GBP',
    payment_options: {
      people: 0,
      qualify: 1,
      deposit_enabled: 1,
      deposit: '50',
      days: '56',
      type: 'flat',
      deposittotal: '50.00',
      message_available:
          'Your order qualifies for a deposit payment of £50.00 which guarantees the price and offer. £50.00 will be charged to your card. The balance is due 8 weeks prior to your departure. We will send you a reminder email.\r\n\r\n\r\n\r\n',
      message_notavailable:
          'The option of paying a deposit is only available when there is more than 8 weeks left before you travel. If you have any queries or would like to speak to one of our [store-owner] experts, please call us free 0800 223 0324. We’re open 9am to 9pm 7 days.',
    },
    checkout_rules: {
      discounts: {
        enable_discounts: 1,
        override_hide_form: 1,
      },
      show_delivery_address: 0,
    },
    user_id: '0',
    is_hotel_cart: 1,
  },
  data: {
    tickets: [
      {
        cart_item_id: '43358033',
        ticket_id: '283063',
        qty: '2',
        data: {
          ac: 'Adult',
        },
        title: 'Disneyland Paris 2-Day/2 Parks Ticket (Hotels)',
        product_id: '277425',
        product_destination_id: '24053',
        tags: [
          'hotels',
          'hotel-park-tickets',
          'app',
          'hotels-calendar-tickets',
        ],
        price: '160.00',
        attributes: {
          5: ['2022-03-11'],
          10: ['06:00:00'],
          fields: [
            {
              attribute_id: '283063-0-13',
              title: 'Full Passenger Name',
              type: 'text',
              format: '',
              required: '1',
              required_for_booking: '0',
              per_qty: '1',
              tip: '',
              backend_only: '0',
              calendar_based: '0',
              values: [],
            },
            {
              attribute_id: '283063-0-5',
              title: 'Date',
              type: 'date',
              format: 'yyyy-mm-dd',
              required: '1',
              required_for_booking: '1',
              per_qty: '0',
              tip: '',
              backend_only: '0',
              calendar_based: '1',
              availability_product_id: '277425',
              availability_ticket_id: '283063',
              values: ['11/03/2022'],
            },
          ],
        },
        year: '',
        date: '2022-03-11',
        time: '06:00',
      },
      {
        cart_item_id: '43358034',
        ticket_id: '283064',
        qty: '2',
        data: {
          ac: 'Child',
        },
        title: 'Disneyland Paris 2-Day/2 Parks Ticket (Hotels)',
        product_id: '277425',
        product_destination_id: '24053',
        tags: [
          'hotels',
          'hotel-park-tickets',
          'app',
          'hotels-calendar-tickets',
        ],
        price: '150.00',
        attributes: {
          5: ['2022-03-11'],
          10: ['06:00:00'],
          fields: [
            {
              attribute_id: '283064-0-13',
              title: 'Full Passenger Name',
              type: 'text',
              format: '',
              required: '1',
              required_for_booking: '0',
              per_qty: '1',
              tip: '',
              backend_only: '0',
              calendar_based: '0',
              values: [],
            },
            {
              attribute_id: '283064-0-5',
              title: 'Date',
              type: 'date',
              format: 'yyyy-mm-dd',
              required: '1',
              required_for_booking: '1',
              per_qty: '0',
              tip: '',
              backend_only: '0',
              calendar_based: '1',
              availability_product_id: '277425',
              availability_ticket_id: '283064',
              values: ['11/03/2022'],
            },
          ],
        },
        year: '',
        date: '2022-03-11',
        time: '06:00',
      },
    ],
    charges: [],
  },
} as CartInterface);

export default cartTestData;
