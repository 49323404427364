/**
 * Data that can be used for testing.
 *
 * @returns
 */
export const transportTestData = () => ({
  outbound_date: '2024-01-10',
  inbound_date: '2024-01-12',
  origin: '',
  destination: '',
  passengers: {
    adults: 2, children: 0, infants: 0, child_ages: [],
  },
  transport_type_id: '',
  sale_cur: 'GBP',
  outbound: [
    {
      id: '283217-20240110-1104',
      product_id: '283217',
      title:
        'London St Pancras to Disneyland Paris (Marne-la-Vallee) via Lille',
      carrier: 'Eurostar',
      transport_type_id: '283214',
      departure_date: '2024-01-10',
      departure_time: '11:04',
      arrival_date: '2024-01-10',
      arrival_time: '15:08',
      duration: '184',
      direct: false,
      cost_cur: 'EUR',
      exchange_rate: 0.84746,
      fares: [
        {
          fare_id: '316253-20240110-1104',
          ticket_id: '316253',
          title:
            'London St Pancras to Disneyland Paris (Marne-la-Vallee) via Lille (Adult)',
          sku: 'EUR-STP-DLP-LIL-A',
          booking_type: 'manual',
          fare_type: 'via Lille',
          fare_group: 0,
          passenger_type: 'Adult',
          min_age: 12,
          max_age: 0,
          attributes: [
            {
              attribute_id: '316253-0-5',
              title: 'Date',
              type: 'date',
              format: 'yyyy-mm-dd',
              required: '1',
              required_for_booking: '1',
              per_qty: '0',
              tip: '',
              backend_only: '0',
              calendar_based: '1',
              availability_product_id: '283217',
              availability_ticket_id: '286766',
              values: [],
            },
            {
              attribute_id: '316253-0-13',
              title: 'Passenger name',
              type: 'text',
              format: '',
              required: '1',
              required_for_booking: '0',
              per_qty: '1',
              tip: '',
              backend_only: '0',
              calendar_based: '0',
              values: [],
            },
            {
              attribute_id: '316253-0-111',
              title: 'Supplier OrderID',
              type: 'text',
              format: '',
              required: '0',
              required_for_booking: '0',
              per_qty: '0',
              tip: '',
              backend_only: '1',
              calendar_based: '0',
              values: [],
            },
            {
              attribute_id: '316253-0-9',
              title: 'Confirmation Number',
              type: 'text',
              format: '',
              required: '0',
              required_for_booking: '0',
              per_qty: '0',
              tip: '',
              backend_only: '1',
              calendar_based: '0',
              values: [],
            },
            {
              attribute_id: '316253-0-110',
              title: 'API OrderID',
              type: 'text',
              format: '',
              required: '0',
              required_for_booking: '0',
              per_qty: '0',
              tip: '',
              backend_only: '1',
              calendar_based: '0',
              values: [],
            },
            {
              attribute_id: '316253-0-10',
              title: 'Time',
              type: 'text',
              format: '',
              required: '0',
              required_for_booking: '0',
              per_qty: '0',
              tip: '',
              backend_only: '1',
              calendar_based: '0',
              values: [],
            },
          ],
          qty: 2,
          date_id: '20240110-1104-283217-lp',
          description: '',
          supplier_api_code: '7015400:8722326:8711184-PUB_STD_SF_X-NH',
          supplier_live_price: 1,
          supplier_api_provider: 'atd_api_est',
          cost: '57.54',
          price: '49.00',
          rrp: '0.00',
          price_options: [{ price_option_id: 'standard', price: '49.00' }],
          class: '',
          supplier_info: {
            booking_info: [
              {
                tariff_code: 'H19PSXARBG',
                service_identifier:
                  '0:DA|9132|ES|2024-01-10T11:04|2024-01-10T13:26',
                service_name: '9132',
              },
              {
                tariff_code: 'H19PSXARBG',
                service_identifier:
                  '0:DA|5036|SN|2024-01-10T14:03|2024-01-10T15:0',
                service_name: '5036',
              },
            ],
          },
        },
      ],
      routes: [
        {
          carrier: 'Eurostar',
          origin: '7015400',
          destination: '8722326',
          duration: '82',
          departure_date: '2024-01-10',
          departure_time: '11:04',
          arrival_date: '2024-01-10',
          arrival_time: '13:26',
        },
        {
          carrier: 'SNCF',
          origin: '8722326',
          destination: '8711184',
          duration: '65',
          departure_date: '2024-01-10',
          departure_time: '14:03',
          arrival_date: '2024-01-10',
          arrival_time: '15:08',
        },
      ],
      price_from: { price: '156', fare_group: 0, cheapest: false },
    },
  ],
  inbound: [
    {
      id: '285596-20240112-1552',
      product_id: '285596',
      title: 'Disneyland Paris (Marne-la-Vallee) to London St Pancras',
      carrier: 'Eurostar',
      transport_type_id: '283214',
      departure_date: '2024-01-12',
      departure_time: '18:03',
      arrival_date: '2024-01-12',
      arrival_time: '19:46',
      duration: '163',
      direct: true,
      cost_cur: 'EUR',
      exchange_rate: 0.84746,
      fares: [
        {
          fare_id: '316256-20240112-1552',
          ticket_id: '316256',
          title:
            'Disneyland Paris (Marne-la-Vallee) to London St Pancras  (Adult)',
          sku: 'EUR-DLP-STP-A',
          booking_type: 'manual',
          fare_type: '',
          fare_group: 0,
          passenger_type: 'Adult',
          min_age: 12,
          max_age: 0,
          attributes: [
            {
              attribute_id: '316256-0-5',
              title: 'Date',
              type: 'date',
              format: 'yyyy-mm-dd',
              required: '1',
              required_for_booking: '1',
              per_qty: '0',
              tip: '',
              backend_only: '0',
              calendar_based: '1',
              availability_product_id: '283216',
              availability_ticket_id: '283241',
              values: [],
            },
            {
              attribute_id: '316256-0-13',
              title: 'Passenger name',
              type: 'text',
              format: '',
              required: '1',
              required_for_booking: '0',
              per_qty: '1',
              tip: '',
              backend_only: '0',
              calendar_based: '0',
              values: [],
            },
            {
              attribute_id: '316256-0-111',
              title: 'Supplier OrderID',
              type: 'text',
              format: '',
              required: '0',
              required_for_booking: '0',
              per_qty: '0',
              tip: '',
              backend_only: '1',
              calendar_based: '0',
              values: [],
            },
            {
              attribute_id: '316256-0-9',
              title: 'Confirmation Number',
              type: 'text',
              format: '',
              required: '0',
              required_for_booking: '0',
              per_qty: '0',
              tip: '',
              backend_only: '1',
              calendar_based: '0',
              values: [],
            },
            {
              attribute_id: '316256-0-110',
              title: 'API OrderID',
              type: 'text',
              format: '',
              required: '0',
              required_for_booking: '0',
              per_qty: '0',
              tip: '',
              backend_only: '1',
              calendar_based: '0',
              values: [],
            },
            {
              attribute_id: '316256-0-10',
              title: 'Time',
              type: 'text',
              format: '',
              required: '0',
              required_for_booking: '0',
              per_qty: '0',
              tip: '',
              backend_only: '1',
              calendar_based: '0',
              values: [],
            },
          ],
          qty: 2,
          date_id: '20240112-1552-285596-lp',
          description: '',
          supplier_api_code: '8711184:8722326:7015400-PUB_STD_SF_X-NH',
          supplier_live_price: 1,
          supplier_api_provider: 'atd_api_est',
          cost: '48.00',
          price: '46.00',
          rrp: '0.00',
          price_options: [{ price_option_id: 'standard', price: '46.00' }],
          class: '',
          supplier_info: {
            booking_info: [
              {
                tariff_code: 'H19PSXARBG',
                service_identifier:
                  '0:DA|9852|SN|2024-01-12T15:52|2024-01-12T17:01',
                service_name: '9057',
              },
            ],
          },
        },
      ],
      routes: [
        {
          carrier: 'Eurostar',
          origin: '8711184',
          destination: '7015400',
          duration: '163',
          departure_date: '2024-01-12',
          departure_time: '18:03',
          arrival_date: '2024-01-12',
          arrival_time: '19:46',
        },
      ],
      price_from: { price: '158', fare_group: 0, cheapest: true },
    },
  ],
});

export default transportTestData;
